<template>
  <div>
    <div class="conflicts-block">
      <div>
        <div class="flex flex-wrap gap-3">
          <div class="pt-1 m-fullwidth">
            <div class="tagging__key mb-2">
              Filter by address
            </div>
            <gl-search-box
              v-model="search"
              button-text="Search"
              class="mr-2 m-fullwidth m-mr-0"
              dark-clear
              :disabled="!search"
              grey
              :min-width="isMobile ? 'auto' : '450px'"
              placeholder="Enter the address"
              @clear="clearSearching"
              @search="searching"
            />
          </div>
          <div class="flex m-fullwidth">
            <div class="mr-2 pt-1 m-fullwidth">
              <GlDatePicker
                v-model="transactionsListDates"
                :disabled-range="disabledRange"
                label="filter by date & time "
                name="Date range"
                @apply="setDate(transactionsListDates)"
                @cancel="handleDateClose"
                @clear="handleDateClose"
              />
            </div>
            <div
              class="flex align-center mt-3 pointer"
              @click="clearFilters"
            >
              <gl-icon
                :height="24"
                name="clear-large-dark"
                :width="24"
              />
              <div class="bold white-space-nowrap">
                Clear all
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="wallets && wallets.length"
        class="mt-3 flex"
      >
        <GlButton
          class="mr-3"
          dark
          :loading="loadingAll"
          title="Keep all"
          @click="handleKeepAll"
        />
        <GlButton
          dark
          :loading="loadingAll"
          title="Apply all"
          @click="handleApplyAll"
        />
      </div>
      <div
        v-if="wallets && wallets.length === 0"
        class="conflicts-empty"
      >
        No unresolved conflicts
      </div>
      <div v-else>
        <div
          v-for="(wallet, index) in wallets"
          :key="index"
          class="mt-3 overflow-auto m-fullwidth"
        >
          <div class="flex space-between">
            <div class="flex-1">
              <div class="flex mb-2 mt-4">
                <div class="mr-2 tagging__key">
                  <div>
                    address
                  </div>
                  <div class="value-label">
                    {{ wallet.oldData.address }}
                  </div>
                </div>
              </div>
              <div class="flex align-center">
                <div class="flex space-between conflicts-sub-block flex-1 mb-2">
                  <div class="flex column flex-2">
                    <div class="flex mb-2 t-gap-3 m-column">
                      <div class="flex-1 mr-2 tagging__key">
                        <div class="white-space-nowrap">
                          Address owner
                        </div>
                        <div
                          class="value-label"
                          :class="{'was-changed': wasChanged(wallet.oldData.owner, 'owner', index)}"
                        >
                          {{ capitalizeFirstLetter(wallet.oldData.owner) || '-' }}
                        </div>
                      </div>
                      <div class="flex-1 mr-2">
                        <div class="tagging__key">
                          Type
                        </div>
                        <GlTag
                          v-if="wallet.oldData.type && wallet.oldData.type.length > 0"
                          class="mr-1 mb-1"
                          :score="wallet.oldData.type[0].score && wallet.oldData.type[0].score"
                          :tag="wallet.oldData.type[0].name && wallet.oldData.type[0].name"
                        />
                        <div v-else>
                          --
                        </div>
                      </div>
                    </div>
                    <div class="mr-2 tagging__key">
                      <div>
                        Description
                      </div>
                      <div
                        class="value-label"
                        :class="{'was-changed': wasChanged(wallet.oldData.description, 'description', index)}"
                      >
                        {{ capitalizeFirstLetter(wallet.oldData.description) || '-' }}
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-end">
                    <button
                      class="gl-button gl-button--dark gl-button--padder gl-button-bulk conflicts-btn height-40"
                      @click="keepConflicts('address', wallet.newData._id)"
                    >
                      Keep
                    </button>
                  </div>
                </div>
                <div class="ml-4 mr-4">
                  <gl-icon
                    :height="24"
                    name="conflicts-arrow"
                    :width="24"
                  />
                </div>
              </div>
            </div>
            <div class="flex column flex-1">
              <div
                v-for="(walletItem, i) in wallet.newData._data"
                :key="i"
                class="mb-2"
              >
                <div class="flex mb-2 mt-4 t-gap-3 ">
                  <div class="tagging__key flex-1">
                    <div>
                      user id
                    </div>
                    <div class="value-label">
                      {{ walletItem._editedBy }}
                    </div>
                  </div>
                  <div class="mr-2 tagging__key flex-1 white-space-nowrap">
                    <div>
                      time of change
                    </div>
                    <div class="value-label">
                      {{ formatDate(walletItem._editedAt, 'dd.MM.yyyy HH:mm') }}
                    </div>
                  </div>
                </div>
                <div class="conflicts-sub-block">
                  <div class="flex">
                    <div class="flex-2">
                      <div class="flex m-column mb-2 t-gap-3">
                        <div class="flex-1 mr-2 tagging__key">
                          <div class="white-space-nowrap">
                            Address owner
                          </div>
                          <div
                            class="value-label"
                            :class="{'to-changed': wallets[index].oldData.owner !== walletItem.owner}"
                          >
                            {{ capitalizeFirstLetter(walletItem.owner) || '-' }}
                          </div>
                        </div>
                        <div class="flex-1 mr-2">
                          <div class="tagging__key">
                            Type
                          </div>
                          <GlTag
                            v-if="walletItem.type && walletItem.type.name"
                            class="mr-1 mb-1"
                            :score="walletItem.type && walletItem.type.score"
                            :tag="walletItem.type && walletItem.type.name"
                            :value="walletItem.type && walletItem.type.name"
                          />
                          <div v-else>
                            --
                          </div>
                        </div>
                      </div>
                      <div class="mr-2 tagging__key">
                        <div>
                          Description
                        </div>
                        <div
                          class="value-label"
                          :class="{'to-changed': wallets[index].oldData.description !== walletItem.description}"
                        >
                          {{ capitalizeFirstLetter(walletItem.description) || '-' }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-tooltip.top="{
                        content: 'You are not able to apply type without score',
                        visible: walletItem.type && isNull(walletItem.type.score)
                      }"
                      class="flex flex-1 flex-end"
                    >
                      <button
                        class="gl-button gl-button--dark gl-button--padder gl-button-bulk conflicts-btn height-40"
                        :disabled="walletItem.type && isNull(walletItem.type.score)"
                        @click="applyConflicts('address', wallet.newData._id, i)"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="wallets && wallets.length > 0"
        class="flex space-between pa-2"
      >
        <div class="flex align-center pa-3 m-column m-pa-0 m-pt-2">
          <div class="mr-2 fs-14 bold  m-fs-12 m-mb-3">
            Rows per page:
          </div>
          <vSelect
            v-model="pagination.perPage"
            class="stat-select__pagination mr-1"
            :clearable="false"
            :options="pagesOptions"
            :searchable="false"
            @input="countChange"
          />
        </div>

        <o-pagination
          v-if="wallets.length"
          class="stat-pagination"
          :current.sync="pagination.currentPage"
          order="right"
          :per-page="pagination.perPage"
          simple
          :total="total"
          @change="pageChange"
        >
          <o-pagination-button
            slot="previous"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page mr-2"
              :disabled="props.page.disabled"
              icon="left"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>

          <o-pagination-button
            slot="next"
            slot-scope="props"
            :page="props.page"
          >
            <gl-menu-item
              class="change-page"
              :disabled="props.page.disabled"
              icon="right"
              :icon-height="24"
              :icon-width="24"
              label=""
              not-outline
            />
          </o-pagination-button>
        </o-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlMenuItem from '@/components/gl-menu-item'
import GlSearchBox from '@/components/gl-search-box'
import vSelect from 'vue-select'
import GlTag from "@/components/gl-tag";
import GlDatePicker from '@/components/gl-date-picker'
// Utils
import { formatDate } from "@/utils/format-date";
import { capitalizeFirstLetter } from "@/utils/text-formatter";
import { toSecondsStart, toSecondsEnd } from '@/utils/format-date'
import moment from "moment";
import { isNull } from "lodash";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import GlButton from '@/components/gl-button.vue'
// Vuex
import { mapActions } from 'vuex'

export default {
  components: {
    GlButton,
    GlIcon,
    vSelect,
    GlTag,
    GlSearchBox,
    GlMenuItem,
    GlDatePicker
  },
  mixins: [deviceWidthMixin],
  props: {
    wallets: {
      type: Array,
      default: () => []
    },
    pagination: {
      type: Object,
      default: () => ({})
    },
    total: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      loading: false,
      loadingAll: false,
      search: '',
      pagesOptions: [5, 10,20, 50, 100],
      transactionsListDates: [],
      startRangeDate: false,
      endRangeDate: false,
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.tab) {
          if (val.query.tab === 'address-conflicts') {
            if (val.query.search) {
              this.search = val.query.search
            }
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.pagination.totalItems = this.total
    this.pagination.totalPages = Math.ceil(this.pagination.totalItems / this.perPage)
  },
  methods: {
    ...mapActions('tagging', ['resolveConflict', 'deleteConflict']),
    isNull,
    formatDate,
    capitalizeFirstLetter,
    toSecondsStart,
    toSecondsEnd,
    handleApplyAll() {
      this.loadingAll = true
      this.resolveConflict({ type: 'allAddresses', index: 0, id: this.wallets[0].newData._id }).then(() => {
        this.searching()
      }).finally(() => {
        this.loadingAll = false
      })
    },
    handleKeepAll() {
      this.loadingAll = true
      this.deleteConflict({ type: 'allAddresses', index: 0, id: this.wallets[0].newData._id }).then(() => {
        this.searching()
      }).finally(() => {
        this.loadingAll = false
      })
    },
    clearFilters() {
      this.search = ''
      this.transactionsListDates = []
      this.$router.replace({ name: 'labeling', query: { tab: `address-conflicts` } }).catch(err => err)
      this.$emit('clear-filters')
    },
    setDate(date) {
      const [from, to] = date

      if (from && to) {
        this.startRangeDate = this.toSecondsStart(from) * 1000;
        this.endRangeDate = this.toSecondsEnd(to) * 1000;
        this.currentPage = 1
        this.loadData();
      } else this.clearDate()
    },
    handleDateClose() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadData();
    },
    clearDate() {
      this.transactionsListDates = []
      this.startRangeDate = undefined;
      this.endRangeDate = undefined;
      this.loadData();
    },
    disabledRange(date) {
      return date > moment().endOf('day')
    },
    loadData() {
      this.$emit('set-date', { from: this.startRangeDate, to: this.endRangeDate })
    },
    searching() {
      this.$emit('searching', this.search)
      this.$router.replace({ name: 'labeling', query: { tab: `address-conflicts`, search: this.search } }).catch(err => err)
    },
    clearSearching() {
      this.search = ''
      this.searching()
      this.$router.replace({ name: 'labeling', query: { tab: `address-conflicts` } }).catch(err => err)
    },
    applyConflicts(type, id, index) {
      this.$emit('apply', type, id, index)
    },
    keepConflicts(type, id) {
      this.$emit('keep', type, id)
    },
    pageChange(event) {
      this.$emit('change-page', event)
    },
    countChange(event) {
      this.$emit('change-count', event)
    },
    wasChanged(val, key, index) {
      return !this.wallets[index].newData._data.every(item => item[key] === val)
    }
  }
}
</script>

<style>
.title {
  font-size: 18px;
  font-weight: 600;
}
.value-label {
  font-weight: 500;
  color: var(--dark);
  text-transform: none!important;
  margin-top: 4px;
}
.conflicts-block {
  background-color: #fff;
  padding: 24px;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6);
}

.conflicts-sub-block {
  background-color: #fff;
  padding: 18px;
  border-radius: 3px;
  border: 1px solid var(--dark-grey-d-3);
}

.conflicts-sub-block:hover {
  border: 1px solid var(--soft-blue);
}

.conflicts-btn {
  /*width: 240px;*/
}

.was-changed {
  color: var(--orange) !important;
  font-weight: 600;
}

.to-changed {
  color: var(--light-green) !important;
  font-weight: 600;
}

.mx-input {
  height: 30px !important;
  background: var(--pale-grey);
}

@media (max-width: 767px) {
  .conflicts-block {
    padding: 12px;
  }
}
</style>
