import { getNestedValueFromObj } from '@/utils/helpers'

export function sortingObjects(array, sortBy) {
  return array.slice().sort((a, b) => {
    for (let i = 0; i < sortBy.length; i++) {
      const { key, order = 'desc' } = sortBy[i]
      const comparison = dynamicCompareObjKeys({ key, order })(a, b)
      if (comparison !== 0) {
        return comparison
      }
    }
    return 0
  })
}

export function dynamicCompareObjKeys({ key, order = 'desc' }) {
  return (a, b) => {
    const aValue = getNestedValueFromObj(a, key)
    const bValue = getNestedValueFromObj(b, key)
    if (order === 'desc') {
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0
    } else {
      return aValue > bValue ? 1 : aValue < bValue ? -1 : 0
    }
  }
}

export const sourcesSortOrderArray = [
  { key: 'share', order: 'desc' },
  { key: 'funds.score', order: 'desc' },
]
