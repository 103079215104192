<template>
  <div class="tagging__sub-block">
    <div class="flex space-between mb-4 align-center">
      <div class="tagging__block-title">
        Address Info
      </div>
      <gl-icon
        class="pointer"
        :disabled="loading"
        :height="24"
        :name="canEdit ? 'edit-block-active' : 'edit-block-disabled'"
        :width="24"
        @click="canEdit && !loading ? showModal = true : null"
      />
    </div>
    <div
      v-if="data.conflict && this.$can('use', 'eth')"
      class="flex mb-4"
    >
      <div
        class="conflicts-marker__block"
      >
        <div>
          There are unresolved labeling conflicts for this address
        </div>
        <gl-icon
          class="pointer"
          :height="24"
          name="double-arrow-right"
          :width="24"
          @click="goToConflict('address', data.address)"
        />
      </div>
    </div>
    <div class="flex mb-4 m-flex-wrap m-gap-3">
      <div class="flex-1 mr-2">
        <div class="tagging__key mb-2">
          Address owner
        </div>
        <VueSkeletonLoader
          v-if="loading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div
          v-else
          class="tagging__value"
        >
          {{ data.owner || '—' }}
        </div>
      </div>
      <div class="flex-1 mr-2">
        <div class="tagging__key mb-2">
          Type
        </div>
        <VueSkeletonLoader
          v-if="loading"
          animation="wave"
          :color="'#bec3d8'"
          :height="20"
          :radius="'2'"
          type="rect"
        />
        <div
          v-else
          class="tagging__value"
        >
          <GlTag
            v-if="data.type && data.type.name"
            class="mr-1 mb-1"
            :score="data.type && data.type.score"
            :tag="data.type && data.type.name"
            :value="data.type && data.type.name"
          />
          <span v-else>—</span>
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="tagging__key mb-2">
        Description
      </div>
      <VueSkeletonLoader
        v-if="loading"
        animation="wave"
        :color="'#bec3d8'"
        :height="20"
        :radius="'2'"
        type="rect"
      />
      <div
        v-else
        class="tagging__value tagging__description"
      >
        {{ data.description || '—' }}
      </div>
    </div>
    <div class="mb-4">
      <VueSkeletonLoader
        v-if="loading"
        animation="wave"
        :color="'#bec3d8'"
        :height="20"
        :radius="'2'"
        type="rect"
      />
      <div
        v-else
      >
        <template v-if="data.tags && data.tags.length">
          <div class="flex">
            <o-table
              class="address-tags-meta-table table__overflow-auto flex-1"
              :class="{ 'o-table-has-pagination': data.tags.length }"
              :data="localData.tagsList"
              hoverable
              :loading="loading"
              :mobile-cards="false"
              :style="[{ 'width': isMobile ? '250px' : '400px' }]"
            >
              <o-table-column
                v-slot="props"
                field="score"
                label="Tag & Score"
                width="220px"
              >
                <GlTag
                  :score="props.row.score"
                  :tag="props.row.name"
                />
              </o-table-column>
              <o-table-column
                v-slot="props"
                field="score"
                label="Metadata"
              >
                <div class="flex align-center tag_prent">
                  <a
                    v-if="props.row.metadata && isValidURL(props.row.metadata)"
                    class="link ellipsis"
                    :href="props.row.metadata"
                    :style="[{ 'max-width': isMobile ? '250px' : '400px' }]"
                    target="_blank"
                  >{{ props.row.metadata }}</a>
                  <div
                    v-else
                    class="ellipsis"
                    :style="[{ 'max-width': isMobile ? '250px' : '400px' }]"
                  >
                    {{ props.row.metadata || '-' }}
                  </div>
                  <div class="tag_child">
                    <gl-menu-item
                      class="sidebar__history-copy"
                      icon="copy"
                      :icon-height="24"
                      :icon-width="24"
                      @click="copy(props.row.metadata)"
                    />
                  </div>
                </div>
              </o-table-column>
            </o-table>
          </div>
        </template>
        <template v-else>
          <div class="tagging__key mb-2">
            Tags
          </div>
          —
        </template>
      </div>
    </div>
    <AddressInfoModal
      v-if="showModal"
      v-model="showModal"
      :data="localData"
      @close="showModal = false"
      @save="$emit('save-address-data', $event)"
    />
    <DeleteTagModal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      :tag="removedTag"
      @close="closeRemoveTagModal"
      @submit="removeTag"
    />
  </div>
</template>

<script>
// Components
import GlTag from '@/components/gl-tag';
import GlIcon from '@/components/gl-icon';
import GlMenuItem from '@/components/gl-menu-item'
import AddressInfoModal from '../modals/AddressInfoModal';
import DeleteTagModal from '../modals/DeleteTagModal';
import VueSkeletonLoader from 'skeleton-loader-vue';
// Vuex
import { mapActions } from "vuex";
// Utils
import { isValidURL } from "@/utils/text-formatter";
// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlTag,
    GlIcon,
    GlMenuItem,
    AddressInfoModal,
    DeleteTagModal,
    VueSkeletonLoader,
  },
  mixins: [deviceWidthMixin],
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      localData: {
        description: '',
        type: '',
        owner: '',
        tags: [],
        meta: [],
        risk: false
      },
      showModal: false,
      showDeleteModal: false,
      showAddModal: false,
      removedTag: '',
      newTag: ''
    }
  },
  watch: {
    data: {
      handler(data) {
        this.localData = {
          address: data.address || this.search,
          description: data.description,
          type: data.type ? data.type.name || data.type : '',
          owner: data.owner,
          risk: data.risk,
          info: data.info,
          tags: data.tags ? data.tags.map(item => item.name) : [],
          tagsList: data.tags ? data.tags.map((item) => ({
            ...item,
            metadata: data.meta && data.meta.find(el => el.tagId === item._id)
              ? data.meta.find(el => el.tagId === item._id).metadata
              : ''
          })) : [],
          meta: data.meta,
        }
      },
    },
  },
  methods: {
    isValidURL,
    async copy(value) {
      await navigator.clipboard.writeText(value)
      this.$toasted.global.success({ message: 'Copied!' })
    },
    ...mapActions('tagging', ['deleteAddressTag', 'addTag']),
    goToConflict(type = 'address', search = '') {
      this.$router.replace({ name: 'labeling', query: { tab: `${type}-conflicts`, search } }).catch(err => err)
    },
    remove(tag) {
      this.showDeleteModal = true;
      this.removedTag = tag;
    },
    closeRemoveTagModal() {
      this.showDeleteModal = false;
      this.removedTag = '';
    },
    removeTag(tag) {
      if (tag) {
        this.deleteAddressTag({ name: tag, address: this.localData.address }).then(({ data, success }) => {
          if (success) {
            this.showDeleteModal = false;
            this.removedTag = '';
            this.$emit('remove-tag', tag)
          } else {
            this.$toasted.global.error({ message: `${data.message}` })
          }
        })
      }
    },
  },
}
</script>

<style>
.address-tags-meta-table .o-table .o-table__th {
  padding-left: 0 !important;
  font-size: 12px;
}

.address-tags-meta-table .o-table .o-table__td {
  padding-left: 0 !important;
}

.tag_prent {
  height: 25px !important;
}

/*.tag_child {*/
/*  display: none;*/
/*}*/

.tag_prent:hover .tag_child {
  display: block;
}

.conflicts-marker__block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  border-radius: 3px;
  background: var(--pale-pink);
  padding: 10px;
}
</style>
